import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import Layout from '../layouts/Layout';
import Seo from '../components/Seo/Seo';
import FullWidthComponent from '../components/FullWidthComponent/FullWidthComponent';

const AboutUs = () => {
  const intl = useIntl();

  const isDeLang = () => intl.locale === 'de';

  return (
    <Layout
      section={'payment'}
      bannerTitle={intl.formatMessage({
        id: 'about_us',
        defaultMessage: 'About Us',
      })}
    >

      <section className="section homepage-content">
        <div className="container">
          <div className="columns">
            <div className="column">
              {isDeLang() ? (
                <div className="content px-6">
                  <p>
                    Wien, die Welthauptstadt der klassischen Musik, bietet jeden Abend eine Vielzahl an außergewöhnlichen Konzerten und Veranstaltungen. Wenn Sie auf der Suche nach dem perfekten klassischen Konzert in Wien sind, helfen wir Ihnen dabei, das Beste aus Ihrem Aufenthalt herauszuholen.
                    Unsere Expertise ermöglicht es Ihnen, mühelos durch die Fülle von Angeboten zu navigieren und die authentischen Highlights zu finden. Von atemberaubenden <strong>Mozart-Konzerten</strong> bis hin zu festlichen <strong>Neujahrskonzerten</strong> und beeindruckenden <strong>Ballettaufführungen</strong> - wir haben die besten Tipps für Sie.
                  </p>
                  <br></br>
                  <p>
                    <strong>Unsere Highlights:</strong>
                    <ul>
                      <li><strong>Schönbrunn Konzerte: </strong>Genießen Sie die majestätische Kulisse des <strong>Schönbrunn Palasts</strong> bei einem unvergesslichen <strong>Konzert</strong>.</li>
                      <li><strong>Mozart Konzerte: </strong>Erleben Sie die Meisterwerke von <strong>Wolfgang Amadeus Mozart</strong> an renommierten Veranstaltungsorten wie der <strong>Wiener Konzerthaus</strong> oder dem <strong>Musikverein Wien</strong>.
                      </li>
                    </ul>
                    <strong>Weitere unvergessliche Erlebnisse:</strong>
                    <ul>
                      <li><strong>Vivaldi Konzerte: </strong>Genießen Sie die faszinierenden Klänge von <strong>Antonio Vivaldi</strong> bei einem <strong>Vivaldi-Konzert</strong> in Wien.</li>
                      <li><strong>Silvesterkonzert: </strong>Feiern Sie den Jahreswechsel mit dem berühmten <strong>Silvesterkonzert</strong> im <strong>Musikverein</strong> oder der <strong>Wiener Staatsoper</strong>.</li>
                    </ul>
                  </p>
                  <p>Ob Sie auf der Suche nach <strong>günstigen Konzerttickets</strong> sind oder spontane Entscheidungen für <strong>Last-Minute-Tickets</strong> treffen möchten, wir bieten Ihnen umfassende Informationen und Empfehlungen. Entdecken Sie täglich neue <strong>Veranstaltungen in Wien</strong> bis hin zu den <strong>Festlichkeiten zum Jahreswechsel 2024.</strong>
                  </p><p>Verpassen Sie nicht die Gelegenheit, Teil der <strong>wienerischen Kultur</strong> zu werden und unvergessliche <strong>klassische Musik</strong>-Erlebnisse zu genießen. Besuchen Sie uns regelmäßig für die neuesten Informationen und <strong>Event-Tipps</strong> in der Musikstadt Wien!
                  </p>
                  <br></br>
                  <p><em>Aber warum hier buchen?</em></p>
                  <p>
                    Als Musikliebhaber, die in Wien leben und über ein Jahrzehnt Erfahrung in diesem Bereich haben, wissen wir genau, was ein klassisches Konzert ausmacht.
                    Als Wiener können wir Ihnen helfen, einfach und schnell den Standort zu finden und stehen Ihnen bei Fragen jederzeit gerne zur Verfügung.
                    Kontaktieren Sie uns einfach unter <a href="mailto:office@viennaeventtickets.com">office@viennaeventtickets.com</a>.
                  </p>
                  <br />
                  <p>Wir glauben, dass Musik Menschen aller Nationalitäten zusammenbringt und, dass Wien der richtige Ort dafür ist.
                    Verlieren Sie also keine weitere Zeit, um Ihre Reise in Wien zu einem unvergesslichen Erlebnis zu machen!
                  </p>
                </div>
              ) : (
                <div className="content px-6">
                  <p>
                    Vienna, the world capital of classical music, offers a multitude of extraordinary concerts and events every evening. If you are looking for the perfect classical concert in Vienna, we are here to help you make the most of your stay.
                    Our expertise allows you to effortlessly navigate through the abundance of offerings and discover the authentic highlights. From breathtaking Mozart concerts to festive New Year's concerts and impressive ballet performances – we have the best tips for you.
                  </p>
                  <br></br>
                  <p>
                    <strong>Our Highlights:</strong>
                    <ul>
                      <li><strong>Schönbrunn Concerts: </strong>Enjoy the majestic setting of Schönbrunn Palace during an unforgettable concert.</li>
                      <li><strong>Mozart Concerts: </strong>Experience the masterpieces of Wolfgang Amadeus Mozart at renowned venues such as the Vienna Concert Hall or the Musikverein Vienna.
                      </li>
                    </ul>
                    <strong>More Unforgettable Experiences:</strong>
                    <ul>
                      <li><strong>Vivaldi Concerts: </strong>Delight in the captivating sounds of Antonio Vivaldi at a Vivaldi concert in Vienna.</li>
                      <li><strong>New Year's Eve Concert: </strong>Celebrate the turn of the year with the famous New Year's Eve concert at the Musikverein or the Vienna State Opera.</li>
                    </ul>
                  </p>
                  <p>Whether you are looking for affordable concert tickets or making spontaneous decisions for last-minute tickets, we offer you comprehensive information and recommendations. Discover new events in Vienna daily, leading up to the festivities for New Year's Eve 2024.
                  </p><p>Don't miss the opportunity to become part of Viennese culture and enjoy unforgettable classical music experiences. Visit us regularly for the latest information and event tips in the music city of Vienna!
                  </p>
                  <br></br>
                  <p><em>But why book here?</em></p>
                  <p>
                    As music enthusiasts living in Vienna and having over a decade of experience in this field, we know exactly what people are looking for in a classical concert.
                    As Viennese, we can help you find the right location easily and quickly and are happy to support you at any time if questions arise.
                    You can simply contact us at <a href="mailto:office@viennaeventtickets.com">office@viennaeventtickets.com</a>.
                  </p>
                  <br />
                  <p>We believe that music brings people of all nationalities together and that Vienna is the right place for making this happen.
                    So don't waste any further time to transform your trip into a memorable one!
                  </p>
                </div>
              )}

            </div>
          </div>
        </div>

      </section>
      <FullWidthComponent />
    </Layout>
  );
};

export default AboutUs;

export const Head = ({ location, pageContext }) => (
  <Seo
    title={pageContext.intl.messages.about_us}
    pathname={location.pathname}
  />
);