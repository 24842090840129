import React from "react";
import { useIntl, Link } from 'gatsby-plugin-react-intl';

const FullWidthComponent = () => {
  const intl = useIntl();

  return (
    <div className="full-width-wrapper">
      <Link to="/" className="button">
        {intl.formatMessage({
          id: 'start_planning',
          defaultMessage: 'tart planning',
        })}
      </Link>
    </div>
  );
};

export default FullWidthComponent;